<template>
    <div class="main">
        <div class="row main-container mt-5">
            <div class="col-20">
                <category/>
            </div>
            <div class="col-80 category-lesson mt-0">
                <Switcher />
            </div>
        </div>
    </div>
</template>
<script>
    import category from '@components/pc/category-rank/category.vue';
    import Switcher from './compoments/switcher.vue'
    export default {
        name: "lesson-list",
        data(){
            return {
                type: "rank"
            }
        },
        components: { 
            category,
            Switcher
        },
    }
</script>