<template>
    <div class="category-sidebar">
        <h4 class="title">
            Ranking
        </h4>
        <h5 class="sub-title">
            {{ $t("lesson.ranking") }}
        </h5>
        <div class="hr" />
        <div class="category">
            <category-menu
                key="all"
                :data="[]"
                label="すべてのカテゴリ"
                slug="all"
                :slug_location="['all']"
                :length="0"
            />
            <category-menu
                v-for="(item, index) in categories.data"
                :key="index"
                :data="item.children"
                :label="item.name"
                :slug="item.slug"
                :slug_location="item.slug_location_arr"
                :length="0"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryMenu from './category-menu.vue';

export default {
    name: "category",
    components: {
        CategoryMenu
    },
    computed: {
        ...mapGetters({
            categories: "lessonCategory/getCategoryMenu",
        })
    },
}
</script>